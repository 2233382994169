export const OnlineSearchList = [
  "oil",
  "shoap",
  "pulse",
  "makhana",
  "rice",
  "namkeen",
  "busicuits",
  "dress",
  "dress 300",
];
//Base Url For Backend Api [Local Host]
// export const BaseApiUrl = "http://localhost:8800";

//render hosting
 export const BaseApiUrl = "https://ecommerce-uniquebajar-server.onrender.com";

// Base Url For Backend Api [Aws Host]
// export const BaseApiUrl = "http://13.232.84.203:8800";